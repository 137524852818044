import styled from 'styled-components'
import { Typography } from 'antd'
import { margin } from '@libs/theme/mixins'

const Title = styled(Typography.Title)`
	margin-top: ${margin('xxl')};
	margin-bottom: ${margin('lg')};
`

const InfoBlock = styled.div`
	max-width: 980px;
	margin-top: ${margin('xl')};
`

const InfoTitle = styled.p`
	margin-bottom: ${margin('sm')};
`

const InfoDesc = styled.div`
	margin-bottom: ${margin('lg')};
`

export default { Title, InfoBlock, InfoTitle, InfoDesc }
