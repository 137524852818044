// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { CaptureConsole } from '@sentry/integrations'

if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn:
			process.env.NEXT_PUBLIC_SENTRY_DSN || 'https://d1822fffa28f4cc78f0c0fd3ca6ad10d@o449774.ingest.sentry.io/5901745',
		// Adjust this value in production, or use tracesSampler for greater control
		tracesSampleRate: process.env.NEXT_PUBLIC_DEPLOYMENT_ENV === 'production' ? 0 : 1.0,
		ignoreUrls: ['localhost', '127.0.0.1'],
		ignoreErrors: [
			'ResizeObserver loop limit exceeded',
			'ResizeObserver loop completed with undelivered notifications',
			"t is not a function. (In 't()', 't' is undefined)", // 3rd party lib error
			/^Illegal invocation/,
		],
		// ...
		// Note: if you want to override the automatic release value, do not set a
		// `release` value here - use the environment variable `SENTRY_RELEASE`, so
		// that it will also get attached to your source maps
		environment: process.env.NEXT_PUBLIC_DEPLOYMENT_ENV || 'development',
		integrations: [
			new CaptureConsole({
				levels: ['error'],
			}),
		],
	})
}
