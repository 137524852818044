import React from 'react'
import { Button } from 'antd'
import Styled from './Styled'
import { Container } from '@libs/components'
import appRoutes from 'app.routes'
import i18n from 'i18next' // context value is not accessible

interface IErrorBoundary {
	error: Error
	componentStack: string | null
	resetError: () => void
}

const ErrorBoundary = ({ error, componentStack, resetError }: IErrorBoundary) => (
	<Container display="flex" alignItems="center" justifyContent="center" flexDirection="column">
		<Styled.Title level={2}>{i18n.t('common.placeholders.errorBoundaryTitle')}</Styled.Title>
		<Button href={appRoutes.dashboard()} type="primary" onClick={resetError}>
			{i18n.t('common.actions.returnHome')}
		</Button>
		{process.env.NODE_ENV !== 'production' && componentStack && (
			<Styled.InfoBlock>
				<Styled.InfoTitle>Error:</Styled.InfoTitle>
				<Styled.InfoDesc>{error.toString()}</Styled.InfoDesc>
				<Styled.InfoTitle>Component Stack:</Styled.InfoTitle>
				<Styled.InfoDesc>{componentStack}</Styled.InfoDesc>
			</Styled.InfoBlock>
		)}
	</Container>
)

export default ErrorBoundary
