import React, { FC, useEffect } from 'react'
import { AppProps } from 'next/app'
import { QueryClientProvider } from 'react-query'
import { ThemeProvider } from '@libs/theme'
import '@libs/localization'
import PageLayout from 'modules/common/PageLayout'
import GlobalModals from 'modules/common/GlobalModals'
import queryClient from 'query.client'
import { TaskUploaderProvider } from 'modules/tasks/TaskUploader/context'
import dayjs from 'dayjs'
import dayjsTimezone from 'dayjs/plugin/timezone'
import { useRouter } from 'next/router'
import { AntdConfigProvider } from '@libs/components'
import { NewOrderContextProvider } from 'modules/orders/context'
import ErrorBoundary from 'modules/common/ErrorBoundary'
import * as Sentry from '@sentry/react'
import { useWhitelabelSettings } from 'modules/branches/hooks'
import { AuthApi } from '@libs/api'
import Head from 'next/head'

// Add timezone plugin to dayjs
dayjs.extend(dayjsTimezone)

const AppInner: FC<AppProps> = ({ Component, pageProps }) => {
	const { data: whitelabel } = useWhitelabelSettings(AuthApi.isAuthenticated())

	return (
		<>
			<Head>
				<link rel="shortcut icon" href={whitelabel?.faviconUrl || '/fav-icon-32.png'} />
			</Head>
			<ThemeProvider
				primaryColor={whitelabel?.primaryColor}
				contrastColor={whitelabel?.contrastColor}
				pageBackgroundColor={whitelabel?.pageBackgroundColor}
			>
				<Sentry.ErrorBoundary fallback={ErrorBoundary} showDialog>
					<AntdConfigProvider>
						<TaskUploaderProvider>
							<GlobalModals.Provider>
								<PageLayout.Provider>
									<Component {...pageProps} />
								</PageLayout.Provider>
							</GlobalModals.Provider>
						</TaskUploaderProvider>
					</AntdConfigProvider>
				</Sentry.ErrorBoundary>
			</ThemeProvider>
		</>
	)
}

const App: FC<AppProps> = props => {
	const router = useRouter()

	useEffect(() => {
		const onRouteChange = () => {
			const mfQueue: string[][] = (window as any)._mfq || []
			mfQueue.push(['newPageView', window.location.href.substr(window.location.origin.length)])
		}

		router.events.on('routeChangeComplete', onRouteChange)
		onRouteChange()

		return () => {
			router.events.off('routeChangeComplete', onRouteChange)
		}
	}, [])

	return (
		<QueryClientProvider client={queryClient}>
			<NewOrderContextProvider>
				<AppInner {...props} />
			</NewOrderContextProvider>
		</QueryClientProvider>
	)
}

export default App
