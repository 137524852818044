import { QueryClient } from 'react-query'
import { message } from 'antd'

const queryClient = new QueryClient({
	defaultOptions: {
		mutations: {
			retry: false,
			onError: error => {
				message.error((error as Error).message)
			},
		},
		queries: {
			retry: false,
			refetchOnWindowFocus: false,
			staleTime: 60000,
		},
	},
})

export default queryClient
